var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "topinfo" }, [
    _c("div", { staticClass: "topinfo-head" }, [
      _c("span", { staticClass: "topinfo-no" }, [
        _vm._v("需求编号：" + _vm._s(_vm.entity.id))
      ]),
      _c(
        "span",
        {
          staticClass: "topinfo-status",
          style: { color: _vm.statusObj.color }
        },
        [_vm._v(_vm._s(_vm.statusObj.title))]
      )
    ]),
    _c("div", { staticClass: "topinfo-body" }, [
      _c("div", { staticClass: "topinfo-title" }, [
        _vm._v(" " + _vm._s(_vm.entity.title) + " ")
      ]),
      _c(
        "div",
        { staticClass: "topinfo-tags" },
        [
          _vm._l(_vm.typeList, function(item, index) {
            return _c("span", { key: index, staticClass: "topinfo-tag" }, [
              _vm._v(_vm._s(item))
            ])
          }),
          _c("span", { staticClass: "topinfo-tag" }, [
            _vm._v(_vm._s(_vm.timeDesc))
          ])
        ],
        2
      ),
      _vm.collection
        ? _c(
            "a",
            {
              staticClass: "topinfo-collection",
              attrs: { href: "javascript:;" },
              on: { click: _vm.onSwitchCollection }
            },
            [
              _vm.entity.inFavorite
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/img/project/icon_collection_yellow@2x.png"),
                      alt: ""
                    }
                  })
                : _c("img", {
                    attrs: {
                      src: require("@/assets/img/project/icon_collection_nor@2x.png"),
                      alt: ""
                    }
                  })
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }