<template>
  <div @click="onJumpDetail" class="item" v-if="entity.auditStatus !== '4'">
    <div class="item-title">{{ entity.title }}</div>
    <div class="item-info">
      <span class="item-tag" :style="{ color: statusObj.color, backgroundColor: statusObj.bgColor }">{{
        statusObj.title
      }}</span>
      <span class="item-tag" v-for="(item, index) in typeList" :key="index">{{ item }}</span>
      <span class="item-tag">{{ String(entity.createTime).slice(0,10) }} - {{ String(entity.endTime).slice(0,10) }}</span>
    </div>
    <div v-if="isAdmin && (role === 'company' || role === 'personal') && entity.status < 3" class="item-action">
      <a @click.stop="onEdit" href="javascript:;">
        <img src="~@/assets/img/project/icon_edit@2x.png" alt="" />
        <span>编辑需求</span>
      </a>
    </div>
  </div>
</template>
<script>
import defaultSettings from '@/config/defaultSettings'
import { Toast } from 'mint-ui'
import { getCookie } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'

export default {
  props: {
    action: {
      type: Boolean,
      default: false
    },
    entity: {
      type: Object,
      default() {
        return {}
      }
    },
    role: {
      type: String,
      default: ''
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isFavorite: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    typeList() {
      const { type_dictText: typeDictText } = this.entity
      if (!typeDictText) return []
      return typeDictText.split(',')
    },
    statusObj() {
      const { projectStatus } = this.entity
      return defaultSettings.statusEnum[projectStatus] || {}
    }
  },
  methods: {
    onJumpDetail() {
      const token = getCookie(ACCESS_TOKEN)
      if (token) {
        const { id } = this.entity
        if (this.isFavorite) {
          this.$router.push('/project/detail/' + id)
          return
        }
        if (this.isAdmin) {
          this.$router.push('/admin/project/detail/' + id)
        } else {
          if (['org', 'employee'].indexOf(this.role) === -1) {
            Toast({
              message: '您不是入驻机构，不能使用此服务',
              duration: 5000
            })
          } else {
            this.$router.push('/project/detail/' + id)
          }
        }
      } else {
        this.$router.push({
          name: 'login'
        })
      }
    },
    onEdit() {
      this.$router.push('/admin/project/edit/' + this.entity.id)
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  padding: 15px;
  background-color: white;
  margin-bottom: 10px;
  box-shadow: 0px 0px 2px 0px rgba(23, 140, 249, 0.1);
  border-radius: 6px;
  &-title {
    font-weight: 600;
    font-size: 15px;
    color: #333333;
    word-break: break-all;
  }
  &-tag {
    height: 24px;
    font-size: 12px;
    color: #666666;
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: rgba(245, 245, 245, 1);
    border-radius: 2px;
    margin-right: 5px;
    margin-top: 5px;
  }
  &-info {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &-action {
    position: relative;
    margin-top: 15px;
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      background-color: #f0f0f0;
      height: 1px;
      transform: scaleY(0.5);
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 17px;
      img {
        width: 15px;
        height: 15px;
      }
      span {
        font-size: 15px;
        margin-left: 10px;
        color: #178cf9;
      }
    }
  }
  /deep/ .ant-tag {
    height: 24px;
    background: rgba(245, 245, 245, 1);
    border-radius: 2px;
    line-height: 24px;
    border: none;
    font-size: 12px;
  }
}
</style>
